<template>
    <div class="subscribe-email-view ViewWrapper">
        <div class="container">
            <div class="row" v-if="fetchingPortalInformation">
                <core-loader :active="true" :with-padding="true"></core-loader>
            </div>
            <div class="row" v-if="displayMain">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <!-- LOGO -->
                    <tenant-branding-logo></tenant-branding-logo>

                    <!-- BODY -->
                    <div class="gk-card no-border my-5 position-relative">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="subscribing" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Email Communication Subscription') }}</p>
                            <p class="mb-0 text-center weight-5 size-6" v-if="hasProbValidTranslation('branding.name')">{{ $t('branding.name') }}</p>
                        </div>

                        <form v-on:submit.prevent="submit" v-if="!subscriptionSucceed" data-vv-scope="subscribeEmailForm">
                            <div class="form-group">
                                <label for="email">{{ $t('Email Address') }}</label>
                                <input id="email" name="vv_email" type="email" class="form-control" aria-describedby="emailHelp" :placeholder="$t('Enter email')" v-validate="'email|required|max:254'" v-model="formFields.email">
                                <span class="small text-danger">{{ errors.first('subscribeEmailForm.vv_email') }}</span>
                            </div>
                            <div class="form-group form-check">
                                <input id="oneToOneEmail" name="oneToOneEmail" type="checkbox" class="form-check-input" aria-describedby="oneToOneEmailHelp" v-model="formFields.oneToOneEmail"/>
                                <label class="form-check-label" for="oneToOneEmail">{{ $t('Type_Personal_Email') }}</label>
                                <small id="oneToOneEmailHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div class="form-group form-check">
                                <input id="marketingEmail" name="marketingEmail" type="checkbox" class="form-check-input" aria-describedby="marketingEmailHelp" v-model="formFields.marketingEmail"/>
                                <label class="form-check-label" for="marketingEmail">{{ $t('Type_Marketing_Email') }}</label>
                                <small id="marketingEmailHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div v-if="action === 'subscribe'">
                                <button type="button" class="btn btn-primary" :disabled="disableSubmit" @click="submit">{{ $t('Subscribe') }}</button>
                            </div>
                            <div v-else>
                                <button type="button" class="btn btn-danger" :disabled="disableSubmit" @click="submit">{{ $t('Unsubscribe') }}</button>
                            </div>
                        </form>

                        <div class="gk-card text-center" v-if="subscriptionSucceed">
                            <p class="weight-3 size-5">{{ $t('Well done') }}</p>
                            <p class="weight-4 text-success mb-0" v-if="action === 'subscribe'">{{ $t('Your subscription has been submitted') }}</p>
                            <p class="weight-4 text-danger mb-0" v-else>{{ $t('Your subscription has been canceled') }}</p>
                        </div>
                    </div>

                    <tenant-box-footer :display-i18n-links="portalInformation.i18nAddOnEnabled"
                                       :display-cloutlayer-brand="portalInformation.productBranding"></tenant-box-footer>
                    <!-- This footer has the Cloutlayer's information. -->
                    <!--
                    <common-box-footer></common-box-footer>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EmailSubscriptionService } from '../common/services/api.service';
import PortalInformationAccessMixin from './Mixins/PortalInformationAccessMixin';
import TenantBoxFooter from './Snippets/TenantBoxFooter';
import TenantBrandingLogo from './Snippets/TenantBrandingLogo';

export default {
    name: 'SubscribeEmailView',
    components: {
        TenantBrandingLogo,
        TenantBoxFooter
    },
    mixins: [
        PortalInformationAccessMixin('SubscribeEmailView')
    ],
    data () {
        return {
            subscribing: false,
            subscriptionSucceed: false, // If true, means that the process ended and the user successfully subscribed.
            formFields: {
                email: '',
                oneToOneEmail: true, // Checked by default
                marketingEmail: true // Checked by default
            }
        };
    },
    computed: {
        displayMain () {
            if (this.fetchingPortalInformation) {
                return false;
            }
            return this.portalInformation !== null;
        },
        displayForm () {
            return !this.subscriptionSucceed;
        },
        displaySuccessMessage () {
            if (this.subscribing) {
                return false;
            }
            return this.subscriptionSucceed;
        },
        disableSubmit () {
            return !this.formFields.oneToOneEmail && !this.formFields.marketingEmail;
        }
    },
    mounted () {
        this.explainRoute().then((result) => {
            if (!result.tenantFound) {
                // Tenant not found. Redirect to the front page.
                window.location.replace('/');
            }

            // If 'subscribe' set the checkboxes to true, else ('subscribe') set them to false.
            if (this.action === 'subscribe') {
                this.formFields.oneToOneEmail = true;
                this.formFields.marketingEmail = true;
            } else {
                this.formFields.oneToOneEmail = false;
                this.formFields.marketingEmail = false;
            }
        });
    },
    methods: {
        async submit () {
            // prevent duplicate.
            // @future : add to all forms in all projects?
            if (this.subscribing) {
                return;
            }

            // Validate the form.
            const result = await this.$validator.validateAll('subscribeEmailForm').then(valid => {
                return Promise.resolve({
                    valid: valid
                });
            });

            // If not valid, do nothing.
            if (!result.valid) {
                return;
            }

            // Start loader.
            this.subscribing = true;

            // Make the requests.
            // let result1 = null;
            // let result2 = null;
            const dataObj = {
                'email': this.formFields.email,
                'emailIdentityProfile': null,
                'status': null,
                'withNotification': false,
                'withConfirmation': false,
                'withDataProcessingConsent': true, // @future Add checkbox!
                'types': [],
                'tenantId': this.tenantId
            };

            // Add types.
            if (this.formFields.oneToOneEmail) {
                dataObj.types.push('ONE_TO_ONE_EMAIL');
            }
            if (this.formFields.marketingEmail) {
                dataObj.types.push('MARKETING_EMAIL');
            }

            // Add status. It is impossible for action to have a value which is not equal to 'subscribe' or 'unsubscribe'.
            if (this.action === 'subscribe') {
                dataObj.status = 'OPTED_IN';
            } else if (this.action === 'unsubscribe') {
                dataObj.status = 'OPTED_OUT';
            }

            await EmailSubscriptionService.subscribe(dataObj).then(({ data }) => {
                return Promise.resolve({
                    succeed: true,
                    data: data,
                    error: null
                });
            }).catch((reason) => {
                return Promise.resolve({
                    succeed: false,
                    data: null,
                    error: reason
                });
            });

            // We do not check the results. We except that both requests succeed.
            // The backend implementation if ultra safe if the email is valid.
            // @future : Implement a more reliable mechanism.

            // Stop loader.
            this.subscribing = false;

            // Set confirmation to succeed.
            this.subscriptionSucceed = true;
        }
    }
};
</script>
